import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import SidebarLayout from 'src/layouts/SidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';

import SuspenseLoader from 'src/components/SuspenseLoader';
import {getEnvironmentVariable} from "./utils/common";

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Pages

const Overview = Loader(lazy(() => import('src/content/overview')));

// Dashboards

const Crypto = Loader(lazy(() => import('src/content/dashboards/Crypto')));

// Applications

const Messenger = Loader(
  lazy(() => import('src/content/applications/Messenger'))
);
const Transactions = Loader(
  lazy(() => import('src/content/applications/Transactions'))
);
const UserProfile = Loader(
  lazy(() => import('src/content/applications/Users/profile'))
);
const UserSettings = Loader(
  lazy(() => import('src/content/applications/Users/settings'))
);

// Components

const Buttons = Loader(
  lazy(() => import('src/content/pages/Components/Buttons'))
);
const Modals = Loader(
  lazy(() => import('src/content/pages/Components/Modals'))
);
const Accordions = Loader(
  lazy(() => import('src/content/pages/Components/Accordions'))
);
const Tabs = Loader(lazy(() => import('src/content/pages/Components/Tabs')));
const Badges = Loader(
  lazy(() => import('src/content/pages/Components/Badges'))
);
const Tooltips = Loader(
  lazy(() => import('src/content/pages/Components/Tooltips'))
);
const Avatars = Loader(
  lazy(() => import('src/content/pages/Components/Avatars'))
);
const Cards = Loader(lazy(() => import('src/content/pages/Components/Cards')));
const Forms = Loader(lazy(() => import('src/content/pages/Components/Forms')));

// Status

const Status404 = Loader(
  lazy(() => import('src/content/pages/Status/Status404'))
);
const Status500 = Loader(
  lazy(() => import('src/content/pages/Status/Status500'))
);
const StatusComingSoon = Loader(
  lazy(() => import('src/content/pages/Status/ComingSoon'))
);
const StatusMaintenance = Loader(
  lazy(() => import('src/content/pages/Status/Maintenance'))
);

// Production components go below
// Chatbots

const ChatbotsStatus = Loader(
  lazy(() => import('src/content/pages/Chatbots/Status'))
);

const ChatsList = Loader(lazy(() => import('src/content/pages/Chats/List')));
const Chat = Loader(lazy(() => import('src/content/pages/Chats/Item')));

const PersonasList = Loader(
  lazy(() => import('src/content/pages/Personas/List'))
);
const PersonaForm = Loader(
  lazy(() => import('src/content/pages/Personas/Item'))
);

const LLMSConfigForm = Loader(
  lazy(() => import('src/content/pages/Personas/LLMSConfigForm'))
);

let routes = [];

const REACT_APP_STAGE = getEnvironmentVariable("REACT_APP_STAGE");
console.log('setting environment to: ', REACT_APP_STAGE);

if (REACT_APP_STAGE === 'dev') {
  routes = [
    {
      path: '',
      element: <BaseLayout />,
      children: [
        {
          path: '/',
          element: <Overview />
        },
        {
          path: 'overview',
          element: <Navigate to="/" replace />
        },
        {
          path: 'status',
          children: [
            {
              path: '',
              element: <Navigate to="404" replace />
            },
            {
              path: '404',
              element: <Status404 />
            },
            {
              path: '500',
              element: <Status500 />
            },
            {
              path: 'maintenance',
              element: <StatusMaintenance />
            },
            {
              path: 'coming-soon',
              element: <StatusComingSoon />
            }
          ]
        },
        {
          path: '*',
          element: <Status404 />
        }
      ]
    },
    {
      path: 'dashboards',
      element: <SidebarLayout />,
      children: [
        {
          path: '',
          element: <Navigate to="tasks" replace />
        },
        {
          path: 'crypto',
          element: <Crypto />
        },
        {
          path: 'messenger',
          element: <Messenger />
        }
      ]
    },
    {
      path: 'management',
      element: <SidebarLayout />,
      children: [
        {
          path: '',
          element: <Navigate to="transactions" replace />
        },
        {
          path: 'transactions',
          element: <Transactions />
        },
        {
          path: 'profile',
          children: [
            {
              path: '',
              element: <Navigate to="details" replace />
            },
            {
              path: 'details',
              element: <UserProfile />
            },
            {
              path: 'settings',
              element: <UserSettings />
            }
          ]
        }
      ]
    },
    {
      path: '/components',
      element: <SidebarLayout />,
      children: [
        {
          path: '',
          element: <Navigate to="buttons" replace />
        },
        {
          path: 'buttons',
          element: <Buttons />
        },
        {
          path: 'modals',
          element: <Modals />
        },
        {
          path: 'accordions',
          element: <Accordions />
        },
        {
          path: 'tabs',
          element: <Tabs />
        },
        {
          path: 'badges',
          element: <Badges />
        },
        {
          path: 'tooltips',
          element: <Tooltips />
        },
        {
          path: 'avatars',
          element: <Avatars />
        },
        {
          path: 'cards',
          element: <Cards />
        },
        {
          path: 'forms',
          element: <Forms />
        }
      ]
    },
    {
      path: '/chatbots',
      element: <SidebarLayout />,
      children: [
        {
          path: 'list',
          element: <ChatbotsStatus />
        }
      ]
    }
  ];
} else {
  routes = [
    {
      path: '',
      element: <SidebarLayout />,
      children: [
        // TODO: implement "system overview" page for '/' and '/overview'
        {
          path: '/',
          element: <Navigate to="/chatbots/list" replace />
        },
        {
          path: '/overview',
          element: <Navigate to="/chatbots/list" replace />
        },
        {
          path: 'status',
          children: [
            {
              path: '',
              element: <Navigate to="404" replace />
            },
            {
              path: '404',
              element: <Status404 />
            },
            {
              path: '500',
              element: <Status500 />
            },
            {
              path: 'maintenance',
              element: <StatusMaintenance />
            },
            {
              path: 'coming-soon',
              element: <StatusComingSoon />
            }
          ]
        },
        {
          path: '*',
          element: <Status404 />
        }
      ]
    },
    {
      path: '/chatbots',
      element: <SidebarLayout />,
      children: [
        {
          path: 'list',
          element: <ChatbotsStatus />
        }
      ]
    },
    {
      path: '/chats',
      element: <SidebarLayout />,
      children: [
        {
          path: 'list',
          element: <ChatsList />
        },
        {
          path: 'get/:cid',
          element: <Chat />
        }
      ]
    },
    {
      path: '/personas',
      element: <SidebarLayout />,
      children: [
        {
          path: 'list',
          element: <PersonasList />
        },
        {
          path: 'edit/:personaId',
          element: <PersonaForm />
        },
        {
          path: 'add',
          element: <PersonaForm />
        },
        {
          path: 'supported_llms',
          element: <LLMSConfigForm />
        }
      ]
    }
  ];
}

export default routes;
